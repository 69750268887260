import { BottomOrderEditCard, Heading, PickupDropEditCard } from '@/components';
import {
  CustomerDetailsCard,
  PackageAndPaymentCard,
  ProductDetailsCard,
  OrderIdCard,
  DeliveryInstructionCard,
} from '@/components/OrderForm';
import { ROUTE } from '@/config';
import { useChangeProduct, useUpdateOrderPricePreview } from '@/hooks';
import { useOrderByUID, useUpdateOrder } from '@/hooksApi/useOrder';
import { useFetchListProvinces, useFetchAreaByProvinceCode } from '@/hooksApi/useRegion';
import useProductStore from '@/stores/productStore';
import { BasicProduct, Order, OrderItem } from '@/types';
import { getOrderPickupAddress, parsePhoneNumber } from '@/utils';
import { useQueryClient } from '@tanstack/react-query';
import { Col, Flex, Form, message, Row, Spin } from 'antd';
import { Store } from 'antd/es/form/interface';
import { ValidateErrorEntity } from 'rc-field-form/lib/interface';
import { useEffect, useState, KeyboardEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
interface EditOrderProps {
  keyValue?: string;
}

const EditOrder = ({ keyValue }: EditOrderProps) => {
  const [selectedProvinceCode, setSelectedProvinceCode] = useState<string | undefined>(undefined);

  const [form] = Form.useForm();
  const { t } = useTranslation();
  const { orderUid } = useParams();
  const location = useLocation();
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const { products, resetProducts } = useProductStore();

  const {
    handleFormUpdated,
    orderPrice,
    setOrderPrice,
    orderPriceLoading,
    customShipmentFee,
    setCustomShipmentFee,
    setPackageSize,
    transformValuesToOrder,
    checkOrderFormValidation,
    handleProductChanged,
  } = useUpdateOrderPricePreview(products, form);

  const { handleAddProduct, handleDeleteProduct, setProducts, handleUpdateProduct } =
    useChangeProduct(handleProductChanged, orderUid);

  const { mutate: mutateEditOrder, isLoading: editOrderLoading } = useUpdateOrder(orderUid!);
  const { data: orderData, isLoading: displayOrderLoading } = useOrderByUID(orderUid!); // Use useOrderByUID to fetch the order data
  const { data: provinceList, isLoading: provinceListLoading } = useFetchListProvinces();
  const { data: areaList, isLoading: areaListLoading } = useFetchAreaByProvinceCode(
    selectedProvinceCode!,
  );

  useEffect(() => {
    return () => {
      form.resetFields();
      resetProducts();
    };
  }, []);

  useEffect(() => {
    if (!areaListLoading && areaList && areaList?.object.length > 0) {
      const area = areaList.object.find((item) => item.uid === orderData?.object.region?.uid);

      form.setFieldsValue({ region_uid: area?.uid });
    }
  }, [areaListLoading, areaList, orderData, keyValue]);

  useEffect(() => {
    form.resetFields();

    if (orderData) {
      setOrderPrice({
        ...orderData.object.preview,
      });

      setCustomShipmentFee(orderData.object.fee_customer_payable, true);

      const provinceCode = provinceList?.object.find(
        (item) => item.province_code === orderData.object.region?.province_code,
      )?.province_code;
      const area_uid =
        provinceCode && provinceCode === selectedProvinceCode
          ? orderData.object.region?.uid
          : undefined;
      if (provinceCode && provinceCode !== selectedProvinceCode)
        setSelectedProvinceCode(provinceCode);

      const primaryPhoneInfo = parsePhoneNumber(orderData.object.phone);
      const additionalPhones: string[] | undefined = orderData.object.additional_phones
        ? JSON.parse(orderData.object.additional_phones)
        : undefined;

      const secondaryPhoneInfo = parsePhoneNumber(additionalPhones?.[0]);

      // Prepopulate the form with fetched order data
      form.setFieldsValue({
        name: orderData.object?.full_name || '',
        address: orderData.object?.address_text || '',
        deliveryInstruction: orderData?.object?.description || '',
        packageSize: orderData?.object?.size || '',
        isFragile: orderData?.object?.is_fragile,
        CodOrPrepaid: orderData?.object?.payment_type,
        CustomerOrMerchant: orderData?.object?.shipment_fee_type,
        orderId: orderData?.object?.custom_id,
        pickupOrDropoff: orderData?.object?.pick_up_type,
        saved_pick_up_address_uid: getOrderPickupAddress(orderData.object as unknown as OrderItem)
          ?.uid,
        freezed_pick_up_address_title:
          orderData.object.status.slug !== 'new'
            ? orderData.object.pick_up?.pick_up_address?.title
            : undefined,
        phoneNumberPrimary: primaryPhoneInfo.phone || '',
        countryCodePrimary: primaryPhoneInfo.countryCode,
        countryCodeSecondary: secondaryPhoneInfo?.countryCode,
        secondaryPhoneNumber: secondaryPhoneInfo?.phone,
        province: provinceCode,
        region_uid: area_uid,
        products: orderData?.object?.products,
      });

      // Set the products if available
      if (orderData?.object?.products) {
        setProducts(orderData?.object.products); // Reset products if data is available
      }
    }
  }, [orderData, form, keyValue]); // Include 'form' in the dependency array

  const onFinish = (values: Store) => {
    const isValid = checkOrderFormValidation({ ...values, products });
    if (isValid) {
      const orderPayload: Order = transformValuesToOrder(values);

      // If saveToAddress is false, directly create the order
      mutateEditOrder(orderPayload, {
        onSuccess: () => {
          setProducts([]);

          queryClient.refetchQueries(['orders-readyToShip', 1]);
          message?.success(t('order_edited_successfully'));
          navigate(location.state?.prevPage);
        },
      });
    }
  };
  const onFinishFailed = (errorInfo: ValidateErrorEntity) => {
    const formValues = form.getFieldsValue();

    checkOrderFormValidation({ ...formValues, products });

    console.warn('Failed: ', errorInfo);
  };

  const handleSetSelectedProvinceCode = (provinceCode?: string) => {
    form.setFieldsValue({ province: provinceCode });
    form.setFieldsValue({ region_uid: undefined });
    form.setFieldValue('region_uid', undefined);
    setSelectedProvinceCode(provinceCode);
  };

  const handleKeyPress = (e: KeyboardEvent<HTMLFormElement>) => {
    if (e.key === 'Enter') {
      e.preventDefault();
    }
  };

  const handleCreateProduct = (product: BasicProduct) => {
    const formValues = form.getFieldsValue();

    const newProductList = [...(formValues.products ?? []), product];

    if (checkOrderFormValidation({ ...formValues, products: newProductList }, true)) {
      handleAddProduct(product);
      return true;
    }
    return false;
  };
  return (
    <Flex vertical>
      {displayOrderLoading ? (
        <Spin size='large' className='flex justify-center items-center' />
      ) : (
        <Form
          form={form}
          name='add-new-order'
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          onValuesChange={handleFormUpdated} // Track form value changes
          onKeyDown={handleKeyPress}
        >
          <Heading
            title={t('edit_order')}
            routeLink={location.state?.prevPage ?? ROUTE.ORDERS}
            fontSize='text-2xl'
            fontWeight='font-black'
            extraClassName='leading-[27.84px] uppercase' // Adjust line-height if needed
          />

          <Row gutter={[24, 16]} className='mt-[34px] mb-3'>
            <Col lg={14} md={24} xs={24}>
              <CustomerDetailsCard
                form={form}
                //onRequiredFieldsUpdate//?
                selectedProvinceCode={selectedProvinceCode}
                setSelectedProvinceCode={handleSetSelectedProvinceCode}
              />

              <DeliveryInstructionCard
                title={t('delivery_instruction')}
                description={t('lorem_ipsum')}
                placeholder={t('do_not_ring_door')}
              />

              <OrderIdCard
                title={t('order_id')}
                description={t('if_you_do_not')}
                placeholder={t('order_id_custom')}
              />
              <ProductDetailsCard
                title={t('product_details')}
                placeholders={{
                  title: t('product_name'),
                  quantity: t('qty'),
                  price: t('price'),
                }}
                products={products}
                handleCreateProduct={handleCreateProduct}
                handleDeleteProduct={handleDeleteProduct}
                handleUpdateProduct={handleUpdateProduct}
              />
              <PickupDropEditCard
                form={form}
                orderStatus={orderData?.object.status.slug}
                pickupOrDropoffProp={orderData?.object?.pick_up_type}
                previewOrderPrice={(formValue) =>
                  handleFormUpdated(
                    { saved_pick_up_address_uid: formValue.saved_pick_up_address_uid },
                    formValue,
                  )
                }
                title={t('pickup_dropoff')}
                option1Label={t('boxy_collects')}
                option2Label={t('you_drop_off')}
                collectDescription={t('boxy_agent_will_collect')}
                dropoffDescription={t('you_drop_off_your_packages')}
              />
            </Col>
            <Col lg={10} md={24} xs={24}>
              <PackageAndPaymentCard
                form={form}
                onPackageSizeChange={setPackageSize}
                orderPrice={orderPrice} // Pass orderPrice as a prop
                orderPriceLoading={orderPriceLoading}
                CodOrPrepaid={orderData?.object?.payment_type} // Pass CodOrPrepaid from orderData
                CustomerOrMerchant={orderData?.object?.shipment_fee_type} // Pass CustomerOrMerchant from orderData
                packageSize={orderData?.object?.size}
                onCustomShipmentFeeChange={setCustomShipmentFee}
                customShipmentFee={customShipmentFee}
              />
            </Col>
          </Row>
          <Row className=' sticky bottom-2'>
            <Col lg={24} md={24} xs={24}>
              <BottomOrderEditCard
                isLoading={editOrderLoading}
                createOrderTitle={t('update')}
                readyToShipTitle={t('create_ready_ship')}
              />
            </Col>
          </Row>
        </Form>
      )}
    </Flex>
  );
};

export default EditOrder;
