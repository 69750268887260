import {
  useFetchListOrderReady,
  useFetchListInprogress,
  useFetchListOnHold,
  useFetchListFinished,
  useFetchListByStatusSlug,
  useFetchListRTO,
  useFetchOrderEnRoute,
  useFetchOrderCollecting,
  useFetchReturningOrigin,
  useFetchReturningWarehouse,
  useFetchActionRequiredOrderList,
} from '@/hooksApi/useOrder';
import { OrderStatusTab } from '@/types/columns.ts';
import { OrderListResponse, OrderRtoResponse } from '@/types';
import { ISearch } from '@/components/TableAction/FilterHeader';
import { formatDateByDayjs, getOrderPickupAddress } from '@/utils';

interface Product {
  name: string;
  quantity?: string | number;
  price: string | number;
}
export interface OrderDataType {
  key: string;
  orderDate: string;
  orderId: string;
  customerName?: string;
  customerAddress?: string;
  orderValue: number;
  platform_code: string;
  custom_id: string;
  status: string;
  pickupLocation: string;
  pickupAddressId: string | null;
  products: Product[];
  driver: {
    phone: string | undefined;
    assign: boolean;
  };
  merchant_action_required: number;
  merchant_action_required_text: string;
  phone: string;
}

const useOrderData = (
  current: number,
  activeTab: OrderStatusTab,
  activeButton: string,
  pageSize: number,
  searchValue?: ISearch,
) => {
  // data with filter
  // Fetch data based on active tab and filter

  const { data: dataItemFirstOnHold } = useFetchListOnHold(current, pageSize, true, searchValue);

  const {
    data: dataListSlug,
    isInitialLoading: loadingListSlug,
    refetch: refetchListSlug,
  } = useFetchListByStatusSlug(activeButton, current, pageSize, searchValue);

  const {
    data: dataItemReady,
    isLoading: loadingToReady,
    refetch: refetchReady,
  } = useFetchListOrderReady(
    current,
    pageSize,
    activeTab === OrderStatusTab.ReadyToShip && activeButton === 'all',
    searchValue,
  );

  const {
    data: dataItemInprogress,
    isLoading: loadingToInprogress,
    refetch: refetchInprogress,
  } = useFetchListInprogress(
    current,
    pageSize,
    activeTab === OrderStatusTab.InProgress && activeButton === 'all',
    searchValue,
  );

  const {
    data: dataItemOnHold,
    isLoading: loadingToOnHold,
    refetch: refetchOnHold,
  } = useFetchListOnHold(
    current,
    pageSize,
    activeTab === OrderStatusTab.OnHold && activeButton === 'all',
    searchValue,
  );

  const {
    data: dataItemRTO,
    isLoading: loadingToRTO,
    refetch: refetchRTO,
  } = useFetchListRTO(
    current,
    pageSize,
    activeTab === OrderStatusTab.RTO && activeButton === 'all',
    searchValue,
  );

  const {
    data: dataItemFinished,
    isLoading: loadingToFinished,
    refetch: refetchFinished,
  } = useFetchListFinished(
    current,
    pageSize,
    activeTab === OrderStatusTab.Finished && activeButton === 'all',
    searchValue,
  );

  const {
    data: dataEnRoute,
    isLoading: loadingToEnRoute,
    refetch: refetchEnRoute,
  } = useFetchOrderEnRoute(
    current,
    pageSize,
    activeTab === OrderStatusTab.InProgress && activeButton === 'en-route',
    searchValue,
  );

  const {
    data: dataActionRequired,
    isLoading: loadingToActionRequired,
    refetch: refetchActionRequired,
  } = useFetchActionRequiredOrderList(
    current,
    pageSize,
    activeTab === OrderStatusTab.OnHold && activeButton === 'action_required',
    searchValue,
  );

  const {
    data: dataCollecting,
    isLoading: loadingToCollecting,
    refetch: refetchCollecting,
  } = useFetchOrderCollecting(
    current,
    pageSize,
    activeTab === OrderStatusTab.InProgress && activeButton === 'collecting',
    searchValue,
  );

  const {
    data: dataReturningOrigin,
    isLoading: loadingToReturningOrigin,
    refetch: refetchReturningOrigin,
  } = useFetchReturningOrigin(
    current,
    pageSize,
    activeTab === OrderStatusTab.RTO && activeButton === 'rto-in-transit',
    searchValue,
  );

  const {
    data: dataReturningWarehouse,
    isLoading: loadingToReturningWarehouse,
    refetch: refetchReturningWarehouse,
  } = useFetchReturningWarehouse(
    current,
    pageSize,
    activeTab === OrderStatusTab.RTO && activeButton === 'rto-received-warehouse',
    searchValue,
  );

  // Expose refetch function for each query based on the active tab
  const refetch = () => {
    switch (activeButton) {
      case 'all':
        refetchInprogress();
        refetchReady();
        refetchOnHold();
        refetchRTO();
        refetchFinished();
        break;
      case 'action_required':
        refetchActionRequired();
        break;
      case 'en-route':
        refetchEnRoute();
        break;

      case 'collecting':
        refetchCollecting();
        break;
      case 'rto-in-transit':
        refetchReturningOrigin();
        break;
      case 'rto-received-warehouse':
        refetchReturningWarehouse();
        break;
      case 'on-hold':
        refetchOnHold();
        break;
      default:
        refetchListSlug();
        break;
    }
  };

  // transform data
  const mapOrderData = (data: OrderListResponse | undefined): OrderDataType[] => {
    if (!data?.object) return [];
    return (
      data?.object?.items?.map((item) => ({
        key: item.uid,
        orderDate: formatDateByDayjs(item.created_at, 'D MMMM YYYY'),
        orderId: item?.platform_code,
        customerName: item?.full_name,
        customerAddress: item?.address_text,
        orderValue: item?.products_value,
        platform_code: item?.platform_code,
        custom_id: item?.custom_id || '---',
        status: item?.status?.slug || '',
        carrier_message: item?.carrier_message || '---',
        pickupLocation: getOrderPickupAddress(item)?.title || '---',
        pickupAddressId: getOrderPickupAddress(item)?.uid || '',
        products: item?.products?.map((product) => ({
          name: product?.title,
          quantity: product?.quantity,
          price: product?.price,
        })),
        driver: {
          phone: item?.driver?.phone,
          assign: Boolean(item?.driver) ?? false,
        },
        merchant_action_required: item?.merchant_action_required || 0,
        merchant_action_required_text: item.merchant_action_required_text || '',
        phone: item.phone,
      })) || []
    );
  };

  // transform data RTO
  // const mapOrderRTOData = (data: any) => {
  //   return (
  //     data?.object?.items?.map((item:any) => ({
  //       key: item.uid,
  //       orderDate: dayjs(item.created_at).format('D MMM YY'),
  //       orderId: item.platform_code,
  //       customerName: item.full_name,
  //       customerAddress: item.address_text,
  //       orderValue: item.products_value,
  //       platform_code: item?.platform_code,
  //       status: item.status.title,
  //       pickupLocation: item?.pick_up_address?.title || '---',
  //       pickupAddressId: item?.pick_up_address?.uid || '',
  //       products: item.products.map((product:any) => ({
  //         name: product.title,
  //         quantity: product.quantity,
  //         price: product.price,
  //       })),
  //       driver: {
  //         phone: item?.driver?.phone,
  //         assign: Boolean(item?.driver) ?? false,
  //       },
  //     })) || []
  //   );
  // };

  let dataReady: OrderDataType[] = [];
  let dataInprogress: OrderDataType[] = [];
  let dataOnHold: OrderDataType[] = [];
  let dataFinished: OrderDataType[] = [];
  let dataRTO: OrderDataType[] = [];

  switch (activeButton) {
    case 'all':
      dataReady = mapOrderData(dataItemReady);
      dataInprogress = mapOrderData(dataItemInprogress);
      dataOnHold = mapOrderData(dataItemOnHold);
      dataFinished = mapOrderData(dataItemFinished);
      dataRTO = mapOrderData(dataItemRTO);
      //
      break;
    case 'action_required':
      dataOnHold = mapOrderData(dataActionRequired);
      break;
    case 'en-route':
      dataInprogress = mapOrderData(dataEnRoute);
      break;
    case 'collecting':
      dataInprogress = mapOrderData(dataCollecting);
      break;
    case 'rto-in-transit':
      dataRTO = mapOrderData(dataReturningOrigin);
      break;
    case 'rto-received-warehouse':
      dataRTO = mapOrderData(dataReturningWarehouse);
      break;
    case 'on-hold':
      dataOnHold = mapOrderData(dataItemOnHold);
      break;
    default:
      dataReady = mapOrderData(dataListSlug);
      dataInprogress = mapOrderData(dataListSlug);
      dataOnHold = mapOrderData(dataListSlug);
      dataFinished = mapOrderData(dataListSlug);
      dataRTO = mapOrderData(dataListSlug);
      break;
  }

  // Active Total
  const totals = getTotalForActiveTab().total || 0;

  // Active Loading
  const isLoading = getTotalForActiveTab().loading || false;

  // active data
  const activeData =
    activeTab === OrderStatusTab.ReadyToShip
      ? dataReady
      : activeTab === OrderStatusTab.InProgress
        ? dataInprogress
        : activeTab === OrderStatusTab.OnHold
          ? dataOnHold
          : activeTab === OrderStatusTab.RTO
            ? dataRTO
            : dataFinished;

  function getTotalForActiveTab() {
    let total;
    let loading;
    switch (activeTab) {
      case OrderStatusTab.ReadyToShip:
        total = dataItemReady?.object?.total;
        loading = loadingToReady;
        break;
      case OrderStatusTab.InProgress:
        total = dataItemInprogress?.object?.total;
        loading = loadingToInprogress;
        break;
      case OrderStatusTab.OnHold:
        total = dataItemOnHold?.object?.total;
        loading = loadingToOnHold;
        break;
      case OrderStatusTab.RTO:
        total = dataItemRTO?.object?.total;
        loading = loadingToRTO;
        break;
      case OrderStatusTab.Finished:
        total = dataItemFinished?.object?.total;
        loading = loadingToFinished;
        break;
      default:
        total = 0;
    }

    if (activeButton === 'en-route') {
      total = dataEnRoute?.object?.total;
      loading = loadingToEnRoute;
    } else if (activeButton === 'action_required') {
      total = dataActionRequired?.object?.total;
      loading = loadingToActionRequired;
    } else if (activeButton === 'collecting') {
      total = dataCollecting?.object?.total;
      loading = loadingToCollecting;
    } else if (activeButton === 'on-hold') {
      total = dataItemOnHold?.object?.total;
      loading = loadingToOnHold;
    } else if (activeButton === 'rto-in-transit') {
      total = dataReturningOrigin?.object?.total;
      loading = loadingToReturningOrigin;
    } else if (activeButton === 'rto-received-warehouse') {
      total = dataReturningWarehouse?.object?.total;
      loading = loadingToReturningWarehouse;
    }
    if (activeButton !== 'all') {
      total = dataListSlug?.object?.total;
      loading = loadingListSlug;
    }

    return { total, loading };
  }

  const count = dataItemFirstOnHold?.object?.total || 0;
  return {
    activeData,
    totals,
    isLoading,
    count,
    refetch,
  };
};

export default useOrderData;
