import { useFetchListOrders } from '@/hooksApi/useOrder';
import { OrderListResponse } from '@/types';
import { ISearch } from '@/components/TableAction/FilterHeader';
import { formatDateByDayjs, getOrderPickupAddress } from '@/utils';

const useOrderListData = (current: number, pageSize: number, searchValue?: ISearch) => {
  // data with filter
  const { data: dataOrderList, isLoading: loadingOrderList } = useFetchListOrders(
    current,
    pageSize,
    searchValue,
  );

  // transform data
  const mapOrderData = (data: OrderListResponse | undefined) => {
    if (!data?.object) return [];
    return (
      data?.object?.items?.map((item) => ({
        key: item.uid,
        orderDate: formatDateByDayjs(item.created_at, 'D MMMM YYYY'),
        orderId: item?.platform_code,
        customerName: item?.full_name,
        customerAddress: item?.address_text,
        orderValue: item?.products_value,
        platform_code: item?.platform_code,
        custom_id: item?.custom_id,
        status: item?.status?.slug || '',
        pickupLocation: getOrderPickupAddress(item)?.title || '---',
        pickupAddressId: getOrderPickupAddress(item)?.uid || '',
        products: item?.products?.map((product) => ({
          name: product?.title,
          quantity: product?.quantity,
          price: product?.price,
        })),
        driver: {
          phone: item?.driver?.phone,
          assign: Boolean(item?.driver) ?? false,
        },
        merchant_action_required: item?.merchant_action_required || 0,
      })) || []
    );
  };

  const listData = mapOrderData(dataOrderList);

  return {
    listData,
    totalsOrderList: dataOrderList?.object?.total || 0,
    loadingOrderList,
  };
};

export default useOrderListData;
