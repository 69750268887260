import React, { useEffect } from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import './index.css';
import './i18n';
import './dayjsSetup';
import { I18nextProvider, useTranslation } from 'react-i18next';
import { ConfigProvider, ThemeConfig } from 'antd';
import { QueryClientProvider, QueryClient } from '@tanstack/react-query';
import { THEME_VARIABLE } from './config/theme.config';
import { DIRECTION_LTR, DIRECTION_RTL, LOCAL_EN } from './config';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { defaultQueryOptions } from './config/queryOptions';
import useLanguageStore from './stores/useLanguageStore';
import * as Sentry from '@sentry/react';
import { initSentry } from './config/sentry.config';
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      ...defaultQueryOptions,
    },
  },
});

// Initialize Sentry with tracing
initSentry();

const theme: ThemeConfig = {
  token: THEME_VARIABLE,
};
export function Main() {
  const { i18n } = useTranslation();
  const { language } = useLanguageStore();

  useEffect(() => {
    // Set direction and language attribute on the document
    document.documentElement.dir = language === LOCAL_EN ? DIRECTION_LTR : DIRECTION_RTL;
    document.documentElement.lang = language;

    // Set the font family dynamically based on the selected language
    document.body.style.fontFamily =
      i18n.language === LOCAL_EN ? 'Lato, sans-serif' : 'Alexandria, sans-serif';
  }, [i18n.language]);

  useEffect(() => {
    i18n.changeLanguage(language);
  }, [language, i18n]);

  return (
    <Sentry.ErrorBoundary fallback={<p>An error occurred.</p>}>
      <ConfigProvider
        theme={theme}
        direction={language === LOCAL_EN ? DIRECTION_LTR : DIRECTION_RTL}
      >
        <I18nextProvider i18n={i18n}>
          <QueryClientProvider client={queryClient}>
            <ReactQueryDevtools initialIsOpen={false} />
            <App />
          </QueryClientProvider>
        </I18nextProvider>
      </ConfigProvider>
    </Sentry.ErrorBoundary>
  );
}

ReactDOM.createRoot(document.getElementById('root')!).render(
  <React.StrictMode>
    <Main />
  </React.StrictMode>,
);
