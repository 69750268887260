import {
  Badge,
  Menu,
  Button,
  Col,
  Drawer,
  Dropdown,
  Flex,
  Layout,
  MenuProps,
  Row,
  Space,
} from 'antd';
import { BellIcon, CloseOutline, MoreOutline, SupportIcon } from '../Icons';
import { useTranslation } from 'react-i18next';
import { LOCAL_AR, LOCAL_EN, ROUTE, STYLE_HEADER, THEME_VARIABLE } from '@/config';
import { useLocation } from 'react-router-dom';
import { identifySelectRoute } from '@/functions';
import { Heading } from '../Heading';
import ButtonHeaderOrders from '../ButtonControls/ButtonHeaderOrders';
import { useState } from 'react';
import { NotificationCard } from '../NotificationCard';
import { GlobalOutlined } from '@ant-design/icons';
import type { MenuInfo } from 'rc-menu/lib/interface';
import useLanguageStore from '@/stores/useLanguageStore';
import { useFetchUser } from '@/hooksApi';
import { useCrisp } from '@/hooks';
import { ProgressBarToast } from './ProgressBarToast';

const Header = () => {
  const [drawerVisible, setDrawerVisible] = useState(false);

  const { i18n, t } = useTranslation();
  const location = useLocation();
  const { language, setLanguage } = useLanguageStore();
  const selectedKey = identifySelectRoute(location.pathname);
  const { showCrispChat } = useCrisp();

  const toggleLanguage = (e: MenuInfo) => {
    const newLanguage = e.key;
    setLanguage(newLanguage);
  };

  const isCurrentRoute =
    `/${selectedKey?.location}` === location.pathname && !location.pathname.includes('import');

  const iconItems: MenuProps['items'] = [
    {
      key: '1',
      label: (
        <Heading
          fontWeight='font-normal'
          fontSize='text-sm'
          extraClassName='leading-[18px] capitalize'
          title={t('clear_all')}
          leadingIcon={
            <CloseOutline width={20} height={20} color={THEME_VARIABLE.customGrayShade5} />
          }
          rotation={false}
        />
      ),
    },
  ];

  const drawerContent = (
    <>
      <NotificationCard
        title={t('announcements')}
        time='19 Sep, 24'
        iconColor={THEME_VARIABLE.customBlue}
        description='Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas eu enim risus. In ac felis id ligula accumsan rutrum vitae vitae turpis. Duis id ultrices magna. Donec ac condimentum sapien, eu sodales ipsum.'
      />
      <NotificationCard
        title={t('order_delivered')}
        time='19 Sep, 24'
        iconColor={THEME_VARIABLE.customGreen}
        description='Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas eu enim risus. In ac felis id ligula accumsan rutrum vitae vitae turpis. Duis id ultrices magna. Donec ac condimentum sapien, eu sodales ipsum.'
      />
      <NotificationCard
        title={t('order_returned')}
        time='19 Sep, 24'
        iconColor={THEME_VARIABLE.customRed}
        description='Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas eu enim risus. In ac felis id ligula accumsan rutrum vitae vitae turpis. Duis id ultrices magna. Donec ac condimentum sapien, eu sodales ipsum.'
      />
    </>
  );

  // Language menu with English and Arabic options
  const languageMenu = (
    <Menu onClick={toggleLanguage}>
      <Menu.Item key={LOCAL_EN}>English</Menu.Item>
      <Menu.Item key={LOCAL_AR}>العربية</Menu.Item>
    </Menu>
  );

  return (
    <Layout className='flex flex-col '>
      <Row>
        <Col md={24} className='flex justify-end mt-4 '>
          <Space size={16}>
            {/* <Button type='primary' onClick={toggleLanguage}>
              Change Language
            </Button> */}
            <Dropdown overlay={languageMenu} trigger={['click']}>
              <Button icon={<GlobalOutlined style={{ fontSize: '26px' }} />} type='text'></Button>
            </Dropdown>
            <Button onClick={showCrispChat} id='open-chat' type='text' className={STYLE_HEADER}>
              <SupportIcon />
            </Button>
            {/*V0 remove*/}
            {/* <Button type='text' className={STYLE_HEADER} onClick={() => setDrawerVisible(true)}>
              <Badge
                dot
                offset={[(i18n.language === LOCAL_EN ? -1 : 1) * 30, 0]}
                color={THEME_VARIABLE.customRed}
              >
                <BellIcon />
              </Badge>
            </Button> */}
          </Space>
        </Col>
      </Row>
      <Row className='mt-6 w-full justify-between'>
        {isCurrentRoute && (
          <Heading title={t(selectedKey?.name || '')} upperCase fontWeight='font-bold' level={2} />
        )}
        {location.pathname === ROUTE.ORDERS && <ButtonHeaderOrders />}
      </Row>
      <Drawer
        closeIcon={<CloseOutline />}
        title={
          <Flex className='justify-between items-center'>
            {t('notifications')}
            <Flex className='items-center gap-x-[10px]'>
              {' '}
              <Dropdown
                menu={{ items: iconItems }}
                trigger={['click']}
                rootClassName='bottomSheet'
                placement='bottomLeft'
              >
                <MoreOutline
                  width={24}
                  height={24}
                  className='cursor-pointer '
                  color={THEME_VARIABLE.customTunaPrimary}
                />
              </Dropdown>{' '}
              <CloseOutline
                width={24}
                height={24}
                color={THEME_VARIABLE.customTunaPrimary}
                className='cursor-pointer'
                onClick={() => setDrawerVisible(false)}
              />
            </Flex>
          </Flex>
        }
        placement={language === LOCAL_EN ? 'right' : 'left'}
        closable={false}
        onClose={() => setDrawerVisible(false)}
        visible={drawerVisible}
        width={270}
      >
        {drawerContent}
      </Drawer>
      <Row>
        <Col md={24}>
          <ProgressBarToast />
        </Col>
      </Row>
    </Layout>
  );
};

export default Header;
