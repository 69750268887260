import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Button, Checkbox, Col, Flex, Layout, MenuProps, Row, Typography } from 'antd';
import {
  BottomSheet,
  TableAction,
  DownloadIcon,
  FilterHeader,
  PickupsIcon,
  Heading,
  ExportIcon,
  ResolveIcon,
  DeleteIcon,
} from '@/components';
import TabBar from '@/components/Tab/TabBar';
import { useTranslation } from 'react-i18next';
import { getColumns } from './list.tsx';
import { OrderStatusTab, StatusEnum } from '@/types/columns.ts';
import styles from './orders.module.css';
import useOrderData, { OrderDataType } from '@/hooks/useOrderData.ts';
import { listStatusButtons } from '@/utils/getStatus.ts';
import useSubmitOrder from '@/hooks/useSubmitOrders.tsx';
import useTabVisibility from '@/hooks/useTabVisibility.ts';
import { ISearch } from '@/components/TableAction/FilterHeader.tsx';
import useSearchStore from '@/hooks/useSearchStore.ts';
import useOrderListData from '@/hooks/useOrderListData.tsx';
import getTabItems from '@/utils/getTabsItems.tsx';
import { useLocation, useNavigate } from 'react-router-dom';
import { OrderColumn } from '@/types/order.ts';
import { downloadFile, formatDateByDayjs } from '@/utils/helper.ts';
import { fetchBulkOrderByQuery } from '@/api/orderService.ts';
import BulkResolveModal from '@/components/ResolveModal/BulkResolveModal.tsx';
import { useBulkCancelOrders, useBulkDeleteOrders } from '@/hooksApi/useOrder.ts';
import { OrderStatusEnum } from '@/config/constants.ts';
import { useQueryClient } from '@tanstack/react-query';
import useConfirmModalStore from '@/stores/useConfirmModalStore.ts';
import { customerIOApiKey } from '@/config/setup.config.ts';

const pageSize = 25;

const Orders = () => {
  // const [allSelectableOrderIds, setAllSelectableOrderIds] = useState<IdsPerPage>({});
  const [isAllSelected, setIsAllSelected] = useState(false);
  // const [selectedItemsByPage, setSelectedItemsByPage] = useState<IdsPerPage>({});

  const [isBulkResolveOpen, setIsBulkResolveOpen] = useState(false);
  // handle active checkbox Item
  const [selectedRowKeys, setSelectedRowKeys] = useState<string[]>([]);
  const [currentPage, setCurrentPage] = useState<number>(1);
  // find active tab and sub tab
  const [activeTab, setActiveTab] = useState<OrderStatusTab>(OrderStatusTab.ReadyToShip);
  const [activeSubTab, setActiveSubTab] = useState<string>('all');

  // search value
  const [searchValue, setSearchValue] = useState<ISearch>({
    search: null,
    searchBy: 'filter_platform_code',
  });

  const { i18n, t } = useTranslation();

  const location = useLocation();
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  // parse query parameters
  const queryParams = new URLSearchParams(location.search);
  const activeTabQuery = queryParams.get('activeTab') as OrderStatusTab;
  const activeSubTabQuery = queryParams.get('activeSubTab') as OrderStatusTab;

  const shouldHasSelectAll =
    activeTab === OrderStatusTab.ReadyToShip ||
    (activeTab === OrderStatusTab.InProgress &&
      [OrderStatusEnum.scheduled, OrderStatusEnum.collecting, OrderStatusEnum.collected].includes(
        activeSubTab as OrderStatusEnum,
      ));
  // Zustand store values search
  const { searchValue: searchTermHeader } = useSearchStore(); //TODO

  const { openConfirm } = useConfirmModalStore();

  // data for table list orders
  const { listData, loadingOrderList, totalsOrderList } = useOrderListData(
    currentPage,
    pageSize,
    searchTermHeader,
  );
  // data tables
  const { activeData, totals, isLoading, count, refetch } = useOrderData(
    currentPage,
    activeTab,
    activeSubTab,
    pageSize,
    searchValue,
  );

  const allOrdersInCurrentPage = useMemo(() => {
    const result = activeData.map((item) => item.key);
    if (isAllSelected) setSelectedRowKeys(result);
    return result;
  }, [activeData[0]?.key]);

  const { mutate: bulkDeleteOrder, isLoading: isBulkDeleteLoading } = useBulkDeleteOrders();
  const { mutate: bulkCancelOrder, isLoading: isBulkCancelLoading } = useBulkCancelOrders();

  // handle request pickup
  const { onSubmit, LoadingBulkRequestPickup } = useSubmitOrder(
    activeTab,
    selectedRowKeys,
    isAllSelected,
  );

  // reset currentPage page when search changed
  useEffect(() => {
    setCurrentPage(1);
  }, [searchTermHeader?.search, searchTermHeader?.searchBy]);

  useEffect(() => {
    // if user changes the url manually
    if (isValidOrderStatusTab(activeTabQuery) && activeTab !== activeTabQuery) {
      setActiveTab(activeTabQuery);
      if (listStatusButtons[activeTabQuery].some((item) => item.value === activeSubTabQuery))
        setActiveSubTab(activeSubTabQuery ?? 'all');
    }
  }, [activeTabQuery, activeSubTabQuery]);

  // Handle when the tab becomes visible again
  const handleTabVisible = () => {
    // ? IMPROVEMENT: set queryKeys to invalidate specific APIs
    queryClient.invalidateQueries();
    // refetch();
    // ! I believe it's not necessary to call refetch function, i replace it with invalidateQueries..
  };

  function isValidOrderStatusTab(value: string): boolean {
    return Object.values(OrderStatusTab).includes(value as OrderStatusTab);
  }

  // Hook to handle tab visibility
  useTabVisibility(() => {}, handleTabVisible);

  // active button
  const checkActive = (item: string) => (activeSubTab === item ? 'primary' : 'default');

  // handle search
  const handleSearchChange = useCallback((value: ISearch) => {
    setSearchValue(value);
    setCurrentPage(1);
  }, []);

  // tabs items
  const tabItems = getTabItems({
    t,
    i18n,
    checkActive,
    handleChangeSubTab,
    count,
  });

  // has search header
  const hasSearchHeader = !!searchTermHeader?.search;

  // menu items dropdown
  const handleExportOrders: MenuProps['onClick'] = async ({ key }) => {
    if (key === 'export') {
      const queryString = selectedRowKeys
        .map((orderId) => `filter_uids[]=${encodeURIComponent(orderId)}`)
        .join('&');

      await downloadFile(queryString, fetchBulkOrderByQuery, `orders-${new Date().getTime()}.csv`);
    }
  };

  const handleChangeTab = (activeTabKey: OrderStatusTab) => {
    resetSelectedOrders();
    setCurrentPage(1);
    setActiveSubTab('all');
    setActiveTabQueryString(activeTabKey);
  };

  function handleChangeSubTab(subTabKey: string) {
    resetSelectedOrders();
    setCurrentPage(1);
    setActiveSubTab(subTabKey);
    setActiveTabQueryString(activeTab, subTabKey);
  }

  const resetSelectedOrders = () => {
    if (isAllSelected) setIsAllSelected(false);
    setSelectedRowKeys([]);
  };

  const setActiveTabQueryString = (activeTabKey: OrderStatusTab, activeSubTabKey?: string) => {
    const params = new URLSearchParams({
      activeTab: activeTabKey,
    });
    if (activeSubTabKey) params.append('activeSubTab', activeSubTabKey);

    navigate(`?${params.toString()}`);
  };

  const handleChangeSelectedRowKeys = (selectedKeys: string[]) => {
    if (isAllSelected) setIsAllSelected(false);
    setSelectedRowKeys(selectedKeys);
  };

  const handleBulkDelete = () => {
    const order_count = isAllSelected ? totals : selectedRowKeys.length;
    openConfirm({
      description: t('cancel_order_confirm', { order_count }),
      cancelText: t('cancel'),
      onConfirm: () => {
        const selectedIds = isAllSelected ? [] : selectedRowKeys;

        if (activeTab === OrderStatusTab.ReadyToShip) {
          bulkDeleteOrder(
            {
              status: OrderStatusEnum.new,
              selectedIds,
            },
            {
              onSuccess: () => {
                resetSelectedOrders();
                refetch();
              },
            },
          );
        } else {
          bulkCancelOrder(
            {
              status: activeSubTab as OrderStatusEnum,
              selectedIds,
            },
            {
              onSuccess: () => {
                resetSelectedOrders();
                refetch();
              },
            },
          );
        }
      },
    });
  };

  const handleToggleIsAllSelected = () => {
    if (isAllSelected) setSelectedRowKeys([]);
    setIsAllSelected(!isAllSelected);
  };

  const menuItems: MenuProps['items'] = [
    {
      key: 'export',
      label: (
        <Heading
          title={t('export_selected_orders')}
          leadingIcon={<ExportIcon />}
          rotation={false}
          loading={isLoading}
        />
      ),
      onClick: handleExportOrders,
    },
    {
      key: 'cancel',
      label: (
        <Heading
          title={activeTab === OrderStatusTab.ReadyToShip ? t('delete') : t('cancel')}
          leadingIcon={<DeleteIcon />}
          rotation={false}
          loading={isBulkDeleteLoading || isBulkCancelLoading}
        />
      ),
      onClick: handleBulkDelete,
    },
  ];
  const hasResolveOnHold = useMemo(
    () => activeData.some((item) => !!item.merchant_action_required),
    [activeData],
  );

  const allOrdersInCurrentPageAreSelected =
    allOrdersInCurrentPage.length === selectedRowKeys.length &&
    new Set(allOrdersInCurrentPage).size === new Set(selectedRowKeys).size &&
    allOrdersInCurrentPage.every((item) => selectedRowKeys.includes(item));

  return (
    <Layout className='overflow-visible relative w-full' rootClassName={styles.antLayoutCustom}>
      <Row className={`w-full ${!hasSearchHeader ? 'my-6' : ''}`}>
        {!hasSearchHeader && (
          <Col md={24} xs={24}>
            <TabBar
              activeTab={activeTab}
              setActiveTab={setActiveTab as any}
              handleChange={(tabKey) => handleChangeTab(tabKey as OrderStatusTab)}
              tabItems={tabItems}
            />
          </Col>
        )}
        <Col md={24} xs={24} className='mt-6 mb-16 z-10'>
          <TableAction<OrderDataType & OrderColumn>
            columns={getColumns(!!searchTermHeader?.search, activeTab, activeSubTab, t) || []}
            selectedRowKeys={selectedRowKeys}
            setSelectedRowKeys={handleChangeSelectedRowKeys}
            data={hasSearchHeader ? listData : activeData}
            pageSize={pageSize}
            current={currentPage}
            loading={hasSearchHeader ? loadingOrderList : isLoading}
            total={hasSearchHeader ? totalsOrderList : totals}
            onPaginationChange={setCurrentPage}
            rowSelectionEnabled
            className='mt-2'
            header={
              hasSearchHeader ? undefined : (
                <>
                  <Flex justify='end'>
                    {activeTab === OrderStatusTab.OnHold && hasResolveOnHold && (
                      <Button
                        onClick={() => setIsBulkResolveOpen(true)}
                        type='primary'
                        className='px-5'
                      >
                        <Heading
                          title={t('bulk_resolve')}
                          leadingIcon={<ResolveIcon />}
                          fontSize='text-xs'
                          fontWeight='font-bold'
                          extraClassName='uppercase leading-[20px]'
                          rotation={false}
                        />
                      </Button>
                    )}
                  </Flex>
                  <FilterHeader
                    includeSearchBox={activeTab !== OrderStatusTab.RTO}
                    filterItems={[
                      activeTab === OrderStatusTab.OnHold ? t('on_hold') : t('order'),
                      t('customer'),
                    ]}
                    setSearchValue={handleSearchChange}
                    searchValue={searchValue}
                  />
                </>
              )
            }
          />
        </Col>
      </Row>
      <Row
        className={`sticky bottom-6 ${selectedRowKeys.length > 0 || isAllSelected ? 'z-50' : '-z-50'}`}
      >
        <div className='relative block w-full overflow-hidden h-[90px]'>
          {hasSearchHeader ? (
            <BottomSheet
              isVisible={selectedRowKeys.length > 0}
              numberSelected={selectedRowKeys.length}
              withoutButton
              total={totals}
              menuItems={menuItems.slice(0, 1)}
            />
          ) : (
            <BottomSheet
              withoutButton={[OrderStatusTab.Finished, OrderStatusTab.RTO].includes(activeTab)}
              isVisible={selectedRowKeys.length > 0 || isAllSelected}
              numberSelected={isAllSelected ? totals : selectedRowKeys.length}
              total={totals}
              labelBtn={
                activeTab === OrderStatusTab.InProgress || activeTab === OrderStatusTab.OnHold
                  ? t('download')
                  : t('ship_now')
              }
              Icon={
                activeTab === OrderStatusTab.InProgress || activeTab === OrderStatusTab.OnHold ? (
                  <DownloadIcon />
                ) : (
                  <PickupsIcon width={20} height={20} />
                )
              }
              onSubmit={onSubmit}
              loadingSubmit={LoadingBulkRequestPickup}
              menuItems={shouldHasSelectAll ? menuItems : menuItems.slice(0, 1)}
              isAllSelected={isAllSelected}
              toggleIsAllSelected={handleToggleIsAllSelected}
              shouldHasSelectAll={allOrdersInCurrentPageAreSelected && shouldHasSelectAll}
            />
          )}
        </div>
      </Row>

      <BulkResolveModal isOpen={isBulkResolveOpen} onClose={() => setIsBulkResolveOpen(false)} />
    </Layout>
  );
};

export default Orders;
