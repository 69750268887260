import React, { ReactNode } from 'react';
import { Button, Dropdown, Flex, MenuProps, Typography } from 'antd';
import { Heading } from '../Heading';
import { CloseOutline, DeleteIcon, EditOutline, ExportIcon, MoreOutline } from '../Icons';
import { useTranslation } from 'react-i18next';
import '@/styles/style.css';
import { useNavigate } from 'react-router-dom';
import { ROUTE } from '@/config';
import { OrderStatusTab } from '@/types';
import { useHandleOrderActions } from '@/hooks/useHandleOrderActions';
import { ItemType } from 'antd/es/menu/interface';

interface BottomSheetProps {
  withoutButton?: boolean;
  isVisible: boolean;
  Icon?: ReactNode;
  numberSelected?: number;
  total?: number;
  labelBtn?: string;
  children?: ReactNode;
  showRecords?: boolean;
  justify?: string;
  menuItems?: MenuProps['items'];
  onSubmit?: () => void;
  loadingSubmit?: boolean;
  uid?: string;
  editDisabled?: boolean;
  deleteLabel?: string;
  activeTab?: OrderStatusTab;
  cancelDisabled?: boolean;
  disableDropdown?: boolean;
  onClickDropdown?: any;
  //
  customMenuItem?: CustomMenuItemData[];
  isAllSelected?: boolean;
  toggleIsAllSelected?: () => void;
  shouldHasSelectAll?: boolean;
}

interface CustomMenuItemData {
  label: string;
  onClick: () => void;
  disabled?: boolean;
  leadingIcon?: ReactNode;
  loading?: boolean;
  key: string;
}

const { Text } = Typography;

const BottomSheet: React.FC<BottomSheetProps> = ({
  withoutButton = false,
  isVisible,
  numberSelected = 0,
  total = 0,
  labelBtn = 'ship now',
  Icon,
  children,
  showRecords = true,
  menuItems,
  onSubmit,
  uid,
  editDisabled = false,
  cancelDisabled = false,
  disableDropdown = false,
  deleteLabel,
  loadingSubmit,
  activeTab,
  onClickDropdown,
  justify = 'flex-end',
  //
  customMenuItem,
  isAllSelected,
  toggleIsAllSelected,
  shouldHasSelectAll,
}) => {
  const isAnimate = isVisible ? 'translate-y-0 opacity-100' : 'translate-y-full opacity-0';
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { handleOrderAction, isLoading } = useHandleOrderActions(
    uid || '',
    activeTab || OrderStatusTab.ReadyToShip,
  );

  const dropDownDividerItem: ItemType = {
    type: 'divider',
  };

  const getCustomMenuItems = (customMenuItems?: CustomMenuItemData[]): ItemType[] => {
    const items: ItemType[] = [];
    customMenuItems?.forEach((item, index) => {
      items.push({
        key: item.key,
        label: (
          <Heading
            title={item.label}
            loading={item.loading}
            leadingIcon={item.leadingIcon}
            disabled={item.disabled}
          />
        ),
        onClick: item.onClick,
      });
      if (index !== customMenuItems.length - 1) items.push(dropDownDividerItem);
    });

    return items ?? [];
  };
  const items: MenuProps['items'] = customMenuItem
    ? getCustomMenuItems(customMenuItem)
    : menuItems || [
        {
          key: '1',
          label: (
            <Heading
              title={t('edit')}
              leadingIcon={<EditOutline />}
              rotation={false}
              disabled={editDisabled}
            />
          ),
          onClick: () => navigate(ROUTE.ORDER_EDIT.replace(':orderUid', String(uid))),
        },
        {
          type: 'divider',
        },
        {
          key: '2',
          label: (
            <Heading
              title={deleteLabel ?? t('cancel')}
              leadingIcon={<DeleteIcon />}
              rotation={false}
              loading={isLoading}
              disabled={cancelDisabled}
            />
          ),
          onClick: handleOrderAction,
        },
      ];

  return (
    <Flex
      className={`absolute gap-4 items-center max-h-[100px] border-solid border-0 border-customGrayShade3 rounded-[10px] shadow-card-dark bottom-0 left-0 right-0 bg-colorWhite p-4 transform transition-all duration-300 opacity-0 ${isAnimate}`}
      justify={justify}
      style={{ transitionProperty: 'transform, max-height' }}
    >
      {children ? (
        children
      ) : (
        <>
          {showRecords && (
            <Text strong>
              {numberSelected}/{total} <Text className='font-normal'>{t('selected')}</Text>
            </Text>
          )}
          {shouldHasSelectAll && total > 1 && numberSelected !== total && (
            <Text
              className={'text-blue-500 cursor-pointer text-center text-base '}
              onClick={toggleIsAllSelected}
            >
              {isAllSelected ? t('clear_all') : t('select_all_orders', { order_count: total })}
            </Text>
          )}
          {!withoutButton && (
            <Button
              style={{ paddingTop: '27px', paddingBottom: '27px' }}
              type='primary'
              className='min-w-[150px]'
              onClick={onSubmit}
              loading={loadingSubmit}
              disabled={loadingSubmit}
            >
              <Heading
                title={labelBtn}
                leadingIcon={Icon}
                fontWeight='font-bold'
                fontSize='text-sm'
                extraClassName='uppercase leading-[20px]'
                rotation={false}
              />
            </Button>
          )}
          {!disableDropdown && (
            <Dropdown
              menu={{ items }}
              trigger={['click']}
              rootClassName='bottomSheet'
              placement='topLeft'
            >
              <Flex className='flex  justify-center cursor-pointer items-center w-[56px] h-[56px] rounded-lg border border-solid border-customTunaPrimary'>
                <MoreOutline width={29} height={29} />
              </Flex>
            </Dropdown>
          )}
        </>
      )}
    </Flex>
  );
};

export default BottomSheet;
