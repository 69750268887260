import { fetchBulkOrderByQuery } from '@/api';
import {
  BottomSheet,
  Card,
  CopyIcon,
  DownloadIcon,
  DriverInfo,
  ExportIcon,
  Heading,
  OrderTracker,
  PickupsIcon,
} from '@/components';
import {
  BottomOrderDetailsCard,
  CustomerDetailCard,
  LastUpdatedCard,
  OrderInfoCard,
  PackageSizeOrderCard,
  PaymentOrder,
  PickupLocationCard,
  ProductDetailCard,
} from '@/components/OrderDetailsCards';
import { OrderStatusEnum, ROUTE } from '@/config';
import { useResolveModalStore } from '@/hooks';
import useOrderTimeline from '@/hooks/useOrderTimeline ';
import useSubmitSingleOrder from '@/hooks/useSubmitSingleOrder';
import { useFetchOrderActivityLogByOrderUID } from '@/hooksApi/useOrder';
import { DecodeSize, OrderColumn, OrderItem, OrderStatusTab } from '@/types';
import {
  convertedText,
  decodeSize,
  downloadFile,
  formatDateByDayjs,
  getOrderPickupAddress,
} from '@/utils';
import { getStatusTagData } from '@/utils/getStatus';
import { Col, Flex, MenuProps, message, Row, Spin } from 'antd';
import { useTranslation } from 'react-i18next';
import { useLocation, useParams } from 'react-router-dom';

const OrderDetails = () => {
  const { orderId } = useParams<{ orderId: string }>();
  const { t } = useTranslation();
  const location = useLocation();

  // Zustand store setters
  const setFullName = useResolveModalStore((state) => state.setFullName);
  const setPlatformCode = useResolveModalStore((state) => state.setPlatformCode);
  const setPhone = useResolveModalStore((state) => state.setPhone);
  const setOrderValue = useResolveModalStore((state) => state.setOrderValue);
  const setAddressTitle = useResolveModalStore((state) => state.setAddressTitle);

  const { timelineItems, isLoading, data } = useOrderTimeline(orderId as string);
  const { data: activityLogData, isLoading: loadingSingleRTO } = useFetchOrderActivityLogByOrderUID(
    orderId as string,
  );

  const response = data?.object;

  // Set values in Zustand store after data is loaded
  if (response) {
    setFullName(response.full_name || '');
    setPlatformCode(response?.platform_code || '');
    setPhone(response.phone || '');
    setOrderValue(String(response.preview?.orders_value || 0));
    setAddressTitle(getOrderPickupAddress(response as unknown as OrderItem)?.title || '');
  }

  const activeTab = getStatusTagData(response?.status?.slug as OrderStatusEnum)
    ?.key as OrderStatusTab;

  const { onSubmit, LoadingRequestPickup } = useSubmitSingleOrder({
    activeTab,
    orderId: orderId as string,
    pickupId: getOrderPickupAddress(response as unknown as OrderItem)?.uid,
    //todoooooooooo
  });

  // menu items dropdown
  const onClickDropdown: MenuProps['onClick'] = async ({ key }) => {
    if (key === 'export' && response?.uid) {
      const queryString = `filter_uids[]=${encodeURIComponent(response?.uid)}`;

      // download export orders
      await downloadFile(queryString, fetchBulkOrderByQuery, `order-${new Date().getTime()}.csv`);
    }
  };

  const menuItems: MenuProps['items'] = [
    {
      key: 'export',
      label: (
        <Heading
          title={t('export')}
          leadingIcon={<ExportIcon />}
          rotation={false}
          loading={isLoading}
        />
      ),
      onClick: onClickDropdown,
    },
  ];

  const handleCopy = () => {
    if (response?.platform_code) {
      navigator.clipboard.writeText(response.platform_code);
      message.success(t('copy'));
    }
  };

  return (
    <div className='flex flex-col min-h-screen justify-between'>
      <Flex vertical rootClassName='min-h-screen mb-0 pb-0'>
        <Flex className='gap-3 items-center'>
          <Heading
            title={t('order_text')}
            subTitle={`#${response?.platform_code || ''}`} // Add # before orderId
            subTitleClassName='font-normal text-2xl leading-[27.84px] relative top-[5px]'
            routeLink={location.state?.prevPage ?? ROUTE.ORDERS}
            fontSize='text-2xl'
            fontWeight='font-black'
            extraClassName='uppercase'
          />
          <CopyIcon onClick={handleCopy} className='cursor-pointer' />
        </Flex>
        {isLoading ? (
          <Spin />
        ) : (
          <Row gutter={[24, 16]} className='mt-[34px]'>
            <Col lg={14} md={24} xs={24}>
              {getStatusTagData(response?.status?.slug as OrderStatusEnum)?.key ===
                OrderStatusTab.RTO && (
                <Card>
                  <DriverInfo assign={Boolean(response?.driver)} driver={response?.driver} />
                </Card>
              )}
              <OrderInfoCard
                title={t('order_info')}
                orderIdLabel={t('order_id_custom_id')}
                orderId={response?.custom_id || '---'}
                // estimateDeliveryLabel={t('estimate_delivery')}
                // estimateDelivery='Jun 2nd 2024'
                trackingAuth={response?.tracking_auth_page || ''}
                trackingUrlLabel={t('tracking_url')}
                trackingUrl={response?.tracking_page || ''}
                status={response?.status?.slug as OrderStatusEnum}
              />
              <CustomerDetailCard
                title={t('customer_details')}
                nameLabel={t('name')}
                name={response?.full_name || '---'}
                phoneLabel={t('phone_number')}
                phoneNumber={response?.phone || '---'}
                regionLabel={t('province')}
                region={response?.region?.province || '---'}
                cityLabel={t('area')}
                city={response?.region?.area_name || '---'}
                addressLabel={t('address')}
                address={response?.address_text || '---'}
              />
              <ProductDetailCard
                title={t('product_details')}
                productName={t('product_name')}
                price={t('price')}
                status={t('status')}
                products={
                  response?.products?.map((product) => ({
                    title: `${product?.title} x ${product?.quantity}`,
                    price: product?.price,
                    status: product.status,
                  })) || []
                }
                currency={'IQD'}
              />
              <PickupLocationCard
                title={t('pickup_location')}
                locationType={t('warehouse')}
                address={getOrderPickupAddress(response as unknown as OrderItem)?.title || '---'}
              />
              <PackageSizeOrderCard
                title={t('package_size')}
                size={t(decodeSize(response?.size as keyof typeof DecodeSize)) || '---'}
                second_text={t('fragile')}
                fragile_text={response?.is_fragile ? t('yes') : t('no')}
                // description={t('up_to_one_kg')}
              />
              {response?.description && (
                <Card>
                  <Heading
                    title={t('delivery_instructions')}
                    fontSize='text-lg'
                    fontWeight='font-bold'
                    extraClassName='uppercase'
                  />
                  <Flex>
                    <Heading
                      title={response?.description || ''}
                      color='text-customGrayShade5'
                      extraClassName='mt-6'
                    />
                  </Flex>
                </Card>
              )}
            </Col>
            <Col lg={10} md={24} xs={24}>
              <PaymentOrder
                title={t('payment')}
                orderFee={t('order_fee')}
                cod={convertedText(response?.payment_type || '')}
                shipingFee={t('shiping_fee')}
                wallet={convertedText(response?.shipment_fee_type || '')}
                orderValue={response?.preview?.orders_value || 0}
                shipmentFee={response?.preview?.orders_shipment_fee || 0}
                // walletFee={-2000}
                total={response?.preview?.orders_value_with_shipment_fee || 0}
                orderValueText={t('order_value')}
                shipmentFeeText={t('shipment_fee')}
                // walletFeeText={t('paid_from_wallet')}
                totalText={t('total')}
                currency={t('iqd')}
                customShipmentFee={response?.fee_customer_payable ?? 0}
                customShipmentFeeText={t('customer_shipping_fee')}
              />
              <LastUpdatedCard
                lastUpdateText={t('last_update')}
                statusText={
                  response?.status?.slug ? t(`merchant_order_status.${response?.status?.slug}`) : ''
                }
                descriptionText={t(response?.status?.title || '')}
                dateText={formatDateByDayjs(
                  response?.statuses?.[0]?.pivot?.created_at,
                  'dddd, DD MMMM YYYY',
                )}
              />
              <OrderTracker timelineItems={activityLogData?.object.items ?? []} />
            </Col>
          </Row>
        )}
      </Flex>

      {!isLoading && activeTab === OrderStatusTab.OnHold ? (
        <Flex className='sticky bottom-4 w-full' rootClassName='w-full'>
          <BottomOrderDetailsCard
            status='ON_HOLD'
            orderId={orderId}
            platformCode={response?.platform_code}
            comment={response?.merchant_action_required_text || ''}
            merchant_action_required={response?.merchant_action_required}
            editDisabled={['hold-resolved', 'on-hold'].includes(
              response?.status?.merchant_title || '',
            )}
            cancelDisabled={['hold-resolved', 'on-hold'].includes(
              response?.status?.merchant_title || '',
            )}
            hideButton={response?.merchant_action_required === 0}
          />
        </Flex>
      ) : (
        <Row className='sticky bottom-6'>
          <div className='relative block w-full overflow-hidden h-[90px]'>
            <BottomSheet
              isVisible={
                activeTab === OrderStatusTab.ReadyToShip || activeTab === OrderStatusTab.InProgress
              }
              showRecords={false}
              labelBtn={
                activeTab === OrderStatusTab.InProgress || activeTab === OrderStatusTab.OnHold
                  ? t('download')
                  : t('ship_now')
              }
              Icon={
                activeTab === OrderStatusTab.InProgress || activeTab === OrderStatusTab.OnHold ? (
                  <DownloadIcon />
                ) : (
                  <PickupsIcon width={20} height={20} />
                )
              }
              onSubmit={onSubmit}
              loadingSubmit={LoadingRequestPickup}
              uid={orderId}
              activeTab={activeTab}
              editDisabled={
                activeTab === OrderStatusTab.InProgress
                  ? !['scheduled', 'out-for-collecting'].includes(
                      response?.status?.merchant_title || '',
                    )
                  : false
              }
              cancelDisabled={
                activeTab === OrderStatusTab.InProgress
                  ? !['scheduled', 'out-for-collecting', 'collecting'].includes(
                      response?.status?.merchant_title || '',
                    )
                  : false
              }
              deleteLabel={activeTab === OrderStatusTab.ReadyToShip ? t('delete') : undefined}
              menuItems={menuItems}
            />
          </div>
        </Row>
      )}
    </div>
  );
};

export default OrderDetails;
