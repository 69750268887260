import { LOCAL_EN, ROUTE, THEME_VARIABLE } from '@/config';
import { identifySelectRoute } from '@/functions';
import { Avatar, ConfigProvider, Flex, Layout, Menu, message, Space, Typography } from 'antd';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, Link } from 'react-router-dom';
import { EditModal } from '../EditModal';
import { BoxyLogo, OrdersIcon, PickupsIcon, FinanceIcon, RightIcon } from '../Icons';
import { useFetchUser } from '@/hooksApi';
import { useAuthStore } from '@/stores/authStore'; // Import your store to handle token
const { Sider } = Layout;
const { Text } = Typography;

const Sidebar = () => {
  const [editModalOpen, setEditModalOpen] = useState(false);
  const { data } = useFetchUser(); // Fetch user data
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const clearToken = useAuthStore((state) => state.clearToken); // Function to clear auth token

  // Check if the user is not a MERCHANT and log them out
  useEffect(() => {
    if (data?.object) {
      if (data?.object?.type !== 'MERCHANT') {
        message?.error(t('wrong_user_type'));
        handleLogOut(); // Call the logout function
      } else {
        if (!data.object.verified_at) message?.error(t('user_not_verified'));
      }
    }
  }, [data]);

  const handleLogOut = useCallback(() => {
    clearToken(); // Clear the token from the store
    //navigate(ROUTE.LOGIN); // Redirect to the login page
  }, [clearToken, navigate]);

  // Identify the selected route based on the current path
  const selectedKey = identifySelectRoute(location.pathname);

  const handleNavigate = useCallback((route: string) => () => navigate(route), [navigate]);

  const activeClass = (route: string) =>
    `text-sm ${selectedKey?.location === route ? 'font-bold' : ''}`;
  return (
    <>
      <ConfigProvider
        theme={{
          token: {
            colorPrimary: THEME_VARIABLE.colorBlack,
            colorBgContainer: THEME_VARIABLE.colorWhite,
            colorText: THEME_VARIABLE.colorBlack,
            colorPrimaryBg: THEME_VARIABLE.customYellowPrimary,
          },
        }}
      >
        <Sider
          breakpoint='sm'
          collapsedWidth='0'
          width={200}
          theme='light'
          style={{ height: 'calc(100vh - 48px)' }}
          className='rounded-lg bg-colorWhite shadow-card-dark border-solid border-0 border-customGrayShade3 flex flex-col justify-between sticky top-6'
        >
          <div>
            <Space
              className='pt-[34px] pb-[30.8px] pl-8 pr-16'
              onClick={() => navigate(ROUTE.ORDERS)}
            >
              <Link to='/orders'>
                <BoxyLogo />
              </Link>
            </Space>
            <Menu
              mode='vertical'
              selectedKeys={[
                selectedKey?.name === 'Unknown' ? ROUTE.DASHBOARD : `/${selectedKey?.location}`,
              ]}
              className='px-4 flex-1 flex flex-col gap-4'
              style={{ border: 'none' }}
            >
              {/*V0 remove*/}
              {/* <Menu.Item
                className='flex items-center'
                key={ROUTE.DASHBOARD}
                icon={<DashboardIcon />}
                onClick={handleNavigate(ROUTE.DASHBOARD)}
              >
                <Text className={activeClass(ROUTE.DASHBOARD)}>{t('dashboard')}</Text>
              </Menu.Item> */}
              <Menu.Item
                className='flex items-center'
                key={ROUTE.ORDERS}
                icon={<OrdersIcon />}
                onClick={handleNavigate(ROUTE.ORDERS)}
              >
                <Text className={activeClass(ROUTE.ORDERS)}>{t('orders')}</Text>
              </Menu.Item>
              <Menu.Item
                className='flex items-center'
                key={ROUTE.PICKUPS}
                icon={<PickupsIcon />}
                onClick={handleNavigate(ROUTE.PICKUPS)}
              >
                <Text className={activeClass(ROUTE.PICKUPS)}>{t('pickups')}</Text>
              </Menu.Item>
              <Menu.Item
                className='flex items-center'
                key={ROUTE.FINANCE}
                icon={<FinanceIcon />}
                onClick={handleNavigate(ROUTE.FINANCE)}
              >
                <Text className={activeClass(ROUTE.FINANCE)}>{t('finance')}</Text>
              </Menu.Item>
              <Menu.Item
                key={ROUTE.SETTINGS}
                onClick={handleNavigate(ROUTE.SETTINGS)}
                className='absolute bottom-6 left-0 right-0  h-[40px] bg-customGrayShade2'
              >
                <Space className=' flex  items-center justify-between  rounded-lg    mt-auto'>
                  <Space className='flex items-center'>
                    <Avatar
                      size={30}
                      src={
                        data?.object?.avatar
                          ? data?.object?.avatar?.abs_url
                          : 'https://joeschmoe.io/api/v1/random'
                      }
                      className='-mt-[2px]'
                    />
                    <Flex vertical className='-space-y-1'>
                      <Text className=' font-normal text-sm leading-[18.2px] text-customTunaPrimary'>
                        {data?.object.first_name} {data?.object.last_name}
                      </Text>
                      <Text className=' font-normal text-[10px] leading-[18.6px] text-customGrayShade5'>
                        {data?.object?.type}
                      </Text>
                    </Flex>
                  </Space>
                  <RightIcon
                    className={`align-middle ${i18n.language === LOCAL_EN ? 'rotate-0' : 'rotate-180'}`}
                  />
                </Space>
              </Menu.Item>
            </Menu>
          </div>
        </Sider>
      </ConfigProvider>
      <EditModal visible={editModalOpen} onClose={() => setEditModalOpen(false)} />
    </>
  );
};

export default Sidebar;
