import { useMemo, useState } from 'react';
import { Button, Checkbox, Col, Flex, Layout, Row, Typography } from 'antd';
import { TableAction, FilterHeader, BottomSheet, DeleteIcon, Heading } from '@/components';
import { useTranslation } from 'react-i18next';
import { getColumns } from './list.tsx';
import '@/styles/pickups.css';
import usePickupData from '@/hooks/usePickupData.ts';
import { useBulkCancelPickup } from '@/hooksApi/usePickup.ts';
import { CheckboxChangeEvent } from 'antd/es/checkbox/Checkbox';
import { useQueryClient } from '@tanstack/react-query';
import useConfirmModalStore from '@/stores/useConfirmModalStore.ts';

const pageSize = 25;

const Pickups = () => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();

  const [currentPage, setCurrentPage] = useState(1);
  const [selectedIds, setSelectedIds] = useState<string[]>([]);
  const [isAllSelected, setIsAllSelected] = useState<boolean>(false);

  // fetch data
  const { dataPickup, totals, isLoading } = usePickupData(currentPage, pageSize);
  const { mutate: bulkCancelPickup, isLoading: isCancelingPickup } = useBulkCancelPickup();

  const { openConfirm } = useConfirmModalStore();

  const allOrdersInCurrentPage = useMemo(() => {
    const result = dataPickup.map((item) => item.key);
    if (isAllSelected) setSelectedIds(result);
    return result;
  }, [dataPickup[0]?.key]);

  const handlePaginationChange = (page: number) => {
    setCurrentPage(page);
  };

  const handleCancelPickup = () => {
    openConfirm({
      description: [t('cancel_pickup_confirm'), t('cancel_pickup_condition')],
      cancelText: t('cancel'),
      onConfirm: bulkCancel,
    });
  };

  const bulkCancel = () => {
    bulkCancelPickup(isAllSelected ? [] : selectedIds, {
      onSuccess: () => {
        queryClient.invalidateQueries(['pickups']);
      },
    });
  };

  const handleChangeSelectedIds = (ids: string[]) => {
    if (isAllSelected) setIsAllSelected(false);
    setSelectedIds(ids);
  };

  const handleToggleIsAllSelected = () => {
    if (isAllSelected) setSelectedIds([]);
    setIsAllSelected(!isAllSelected);
  };

  const allOrdersInCurrentPageAreSelected =
    allOrdersInCurrentPage.length === selectedIds.length &&
    new Set(allOrdersInCurrentPage).size === new Set(selectedIds).size &&
    allOrdersInCurrentPage.every((item) => selectedIds.includes(item));

  return (
    <Layout className='overflow-visible relative w-full'>
      <Row className='w-full'>
        <Col md={24} xs={24} className='mt-6 mb-16'>
          <TableAction
            columns={getColumns(t)}
            data={dataPickup}
            pageSize={pageSize}
            current={currentPage}
            total={totals}
            customClassName='table-pickup'
            onPaginationChange={handlePaginationChange}
            loading={isLoading}
            selectedRowKeys={selectedIds}
            setSelectedRowKeys={handleChangeSelectedIds}
            rowSelectionEnabled
            //*V0 remove*/
            // header={
            //   <FilterHeader
            //     filterItems={[t('on_hold'), t('customer'), '4568795546']}
            //     includeSearchBox={false}
            //   />
            // }
          />
        </Col>
      </Row>
      <Row className={`sticky bottom-6 ${selectedIds.length > 0 ? 'z-50' : '-z-50'}`}>
        <div className='relative block w-full overflow-hidden h-[90px]'>
          <BottomSheet
            disableDropdown
            isVisible={selectedIds.length > 0}
            numberSelected={isAllSelected ? totals : selectedIds.length}
            total={totals}
            labelBtn={t('cancel')}
            Icon={<DeleteIcon />}
            onSubmit={handleCancelPickup}
            loadingSubmit={isCancelingPickup}
            isAllSelected={isAllSelected}
            toggleIsAllSelected={handleToggleIsAllSelected}
            shouldHasSelectAll={allOrdersInCurrentPageAreSelected && totals > 1}
          />
        </div>
      </Row>
    </Layout>
  );
};

export default Pickups;
